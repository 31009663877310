.sunBox {
    text-align: center;
    padding: 20px;
}
.sunBox img {
    padding-bottom: 10px;
}
.boxNext {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: white;
}

.moonBox {
    background-color: #892959;
    padding: 30px;
    color: white;
    height: 800px;
  
}