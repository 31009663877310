.box {
    display: flex;
    justify-content: center;
   
    
  }

  .box img {
    width: 120px;
    height: 170px;
    z-index: 999;
  }

  .boxCalender {
    background-color: #892959;
    width: 320px;
    height: 220px;
    border-radius: 10px;
    color: white;
    text-align: center;
    padding: 20px;
    
    
  }

  .boxCalender h1{
    margin-top: 90px;
  }

  .circle {
    width: 240px;
    height: 240px;
    border-radius: 50%;
    border: 18px solid #eee;
    background-color: white;
    z-index: 99;
  }



  .boxBack {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* background: linear-gradient(to right, rgb(86, 47, 99), white); */
    background: linear-gradient(to right, rgb(115, 78, 128), white);
  }
  
  .container-fluid {
    position: relative;
    z-index: 1;
  }