.handBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}
.handImage {
 width: 100px;
 margin-top: 80px;
}

.moonBox1 {
    background-color: #892959;
    padding: 30px;
    color: white;
    height: 600px;
    
}