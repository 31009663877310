.foodBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}
.foodPic {
    display: flex;
    justify-content: center;
    align-items: center;
    
}