.moonBox2 {
    background-color: #892959;
    padding: 30px;
    color: white;
    width: 1000px;
    height: 765px;
    overflow: hidden;
    position: relative;
}

.contentPhoto {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.icon {
    cursor: pointer;
    color: yellow;
    z-index: 9999;
    position: relative;
}

.line {
    background-color: red;
    width: 1px;
    height: 260px;
    position: absolute;
    top: 220px;
    right: 200px;
    transform: rotate(63deg); 
    transform-origin: top left; /* Ensure rotation around the top left corner */
    z-index: 33;
}
.circle {
    color: rgb(216, 43, 43);
    opacity: 0.3;
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    top: 310px;
    right: 400px;
    border: none;
    background-color: red;
    z-index: 33;
}

.lineUpper {
    background-color: red;
    width: 1px;
    height: 190px;
    position: absolute;
    top: 490px;
    right: 300px;
    transform: rotate(77deg); 
    transform-origin: top left; /* Ensure rotation around the top left corner */
    z-index: 33;
}
.circleUpper {
    color: rgb(216, 43, 43);
    opacity: 0.3;
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    top: 500px;
    right: 455px;
    border: none;
    background-color: red;
    z-index: 33;
}
.lineShoulder {

    background-color: red;
    width: 1px;
    height: 200px;
    position: absolute;
    top: 160px;
    right: 280px;
    transform: rotate(70deg); 
    transform-origin: top left; /* Ensure rotation around the top left corner */
    z-index: 33;

}

.circleShoulder {
    color: rgb(216, 43, 43);
    opacity: 0.3;
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    top: 200px;
    right: 440px;
    border: none;
    background-color: red;
    z-index: 33;
}

.lineArm {
    background-color: red;
    width: 1px;
    height: 220px;
    position: absolute;
    top: 400px;
    right: 180px;
    transform: rotate(54deg); 
    transform-origin: top left; /* Ensure rotation around the top left corner */
    z-index: 33;
}

.circleArm {
    color: rgb(216, 43, 43);
    opacity: 0.3;
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    top: 500px;
    right: 330px;
    border: none;
    background-color: red;
    z-index: 33;
}

.lineBack {
    background-color: red;
    width: 1px;
    height: 320px;
    position: absolute;
    top: 240px;
    right: 200px;
    transform: rotate(85deg); 
    transform-origin: top left; /* Ensure rotation around the top left corner */
    z-index: 33;
}

.circleBack {
    color: rgb(216, 43, 43);
    opacity: 0.3;
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    top: 240px;
    right: 490px;
    border: none;
    background-color: red;
    z-index: 33;
}

 .lineSweat {
    width: 250px;
    position: absolute;
    top: 220px;
    right: 400px;
    transform: rotate(3deg); 
    transform-origin: top left; /* Ensure rotation around the top left corner */
    z-index: 33;

 }
  

  